import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/system";
import { Controller, useFormContext } from "react-hook-form";
import Calender from "../iconify/Calender";
import dayjs from "dayjs";
// ---------------------------------------------

const defaulttxtFieldSx = {
  width: "100%",
  borderRadius: "5px",
  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const defaultPopperSx = {
  "& .MuiPaper-root": {
    // border: "1px solid black",
  },
  "& .MuiCalendarPicker-root": {
    // backgroundColor: "rgba(45, 85, 255, 0.4)"
    "& .MuiPickersPopper-root": {
      inset: "25% auto auto 60%!important",
    },
  },
  "& .Mui-selected": {
    // background:
    //     "linear-gradient(178.18deg, #FFD00D -13.56%, #FF6E3A 158.3%) !important",
    background: (theme) => theme.palette.primary.light,
    borderRadius: "8px !important",
    color: "#fff !important",
  },
  "& .MuiPickersDay-root:not(.Mui-selected)": {
    borderColor: "transparent",
    "&:hover": {
      background: (theme) => theme.palette.primary.light,
    },
  },
  "& .MuiPickersDay-dayWithMargin": {
    "&:hover": {
      background: "#fdcd855c",
    },
    color: "#5C5C5C",
    fontSize: "12px",
    fontWeight: 700,
  },
  "& .MuiPickersDay-dayOutsideMonth": {
    color: "#B3B2B2",
  },
  "& .MuiDayPicker-weekDayLabel": {
    color: (theme) => theme.palette.primary.light,
    fontWeight: 700,
    // borderRadius:'10px'
    // backgroundColor: "rgba(50, 136, 153)"
  },

  "& .MuiButton-root": {
    background: (theme) => theme.palette.primary.main,
    margin: "auto",
    color: "#fff",
    borderRadius: "100px",
    marginBottom: "20px",
    // padding: '12px 35px'
  },
  "& .MuiIconButton-root": {
    "&:hover": {
      background: (theme) => theme.palette.primary.light,
    },
  },
  "& .MuiTabs-root": {
    backgroundColor: (theme) => theme.palette.primary.light,
  },
  ".MuiBox-root": {
    margin: "0px",
  },
};

export default function YearCustomDatePicker({
  hideInput = false,
  textLabel,
  inputFormat,
  name,
  onChange,
  setValue,
  defaultDate,
  disabled,
  disablePast,
  disableFuture,
  control,
  error,
  excludeDays,
  ...other
}) {

  const initialDate = defaultDate ? dayjs(defaultDate) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            defaultValue={initialDate}
            format={inputFormat == "MM/YYYY" ? "MM-YYYY" : "DD-MM-YYYY"}
            fullWidth
            disablePast={disablePast}
            disableFuture={disableFuture}
            value={field.value && dayjs(field.value)}
            disabled={disabled}
            onAccept={(date) => {
              const formattedDate = date ? date.format("YYYY-MM-DD") : null;
              setValue(name, formattedDate);
            }}
            onChange={(date) => {
              const formattedDate = date ? date.format("YYYY-MM-DD") : null;
              setValue(name, formattedDate);
              onChange !== undefined && onChange(date);
            }}
            sx={defaulttxtFieldSx}
            openTo="day"
            dayOfWeekFormatter={(day) => {
              return day.label?.toUpperCase();
            }}
            inputFormat={inputFormat == "MM/YYYY" ? inputFormat : "YYYY-MM-DD"}
            views={
              inputFormat == "MM/YYYY"
                ? ["month", "year"]
                : ["day", "month", "year"]
            }
            showDaysOutsideCurrentMonth
            components={{
              OpenPickerIcon: Calender,
            }}
            PopperProps={{
              sx: defaultPopperSx,
            }}
            renderInput={
              hideInput
                ? ({ inputRef, InputProps }) => (
                    <Box ref={inputRef}>{InputProps?.endAdornment}</Box>
                  )
                : (params) => (
                    <>
                      <TextField
                        {...params}
                        sx={defaulttxtFieldSx}
                        {...other}
                        error={!!error}
                        helperText={error?.message}
                        id={name}
                      />
                    </>
                  )
            }
            shouldDisableDate={(date) => {
              // disable Friday and Saturday
              if (excludeDays) {
                return date.day() === 5 || date.day() === 6;
              }
              return false;
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
