import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCountries,
  getAllJobs,
  getAllCurrencies,
  createJobServices,
  getAllClientsService,
  getClientByIdService,
  getOpponentsService,
  getAdministrativeService,
  getLawyersService,
  getConsultantsService,
  getSubAttIntinalService,
  getSubAttAppealService,
  getSubAttDiscriminationService,
  createOpponentService,
  createClientService,
  getAccountingBranchService,
  getCardsService,
  createRoleService,
  deleteClientsService,
  updateClientsService,
  getGroupsService,
  getSchedules,
  getEmployeeDetails,
  filterClientService,
  resetPasswordService,
  getAllClientsListService,
  getClientByPhoneService,
} from "../services/general_service";
import { clientsColumnsList } from "../../utils/columnsLists";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingCountries: false,
  countries: [],
  isLoadingJobs: false,
  jobs: [],
  isLoadingCurrencies: false,
  currencies: [],
  isCheked: null,
  isLoadingCreate: false,
  activeStepperNumber: 0,
  tab: "0",
  clients: [],
  isLoading: false,
  opponents: [],
  administrative: [],
  lawers: [],
  consultants: [],
  subAttInitial: [],
  subAttAppeal: [],
  subAttDim: [],
  delegationTab: "0",
  expertTab: "0",
  isLoadingLawyers: false,
  tapEditPage: "0",
  contractSelected: "no_data",
  radioState: "",
  slides: "",
  isChekedSlideButton: false,
  accountingBranch: [],
  cardsId: [],
  rollTab: "0",
  clientInfo: {},
  userId: null,
  collTab: "0",
  oppTab: "0",
  groups: [],
  empId: null,
  empName: null,
  schedulesData: [],
  isLoadingSchedules: false,
  employeeDetails: {},
  isLoadingEmployeeDetails: false,
  columnsList: clientsColumnsList,
  sTab: "0",
};

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setSTab: (state, action) => {
      state.sTab = action.payload;
    },
    setEmployeeName: (state, action) => {
      state.empName = action.payload;
    },
    setIsChecked: (state, action) => {
      state.isCheked = action.payload;
    },

    setStepperNumber: (state, action) => {
      state.activeStepperNumber = action.payload;
    },

    setDelegationNoteTab: (state, action) => {
      state.delegationTab = action.payload;
    },
    setExpertTab: (state, action) => {
      state.expertTab = action.payload;
    },

    setEditPageTab: (state, action) => {
      state.tapEditPage = action.payload;
    },
    setContractChecked: (state, action) => {
      state.contractSelected = action.payload;
    },

    setRadioGroup: (state, action) => {
      state.radioState = action.payload;
    },
    setSlides: (state, action) => {
      state.slides = action.payload;
    },
    setIsChekedSlideBotton: (state, action) => {
      state.isChekedSlideButton = action.payload;
    },
    setRolTab: (state, action) => {
      state.rollTab = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setCollectionTab: (state, action) => {
      state.collTab = action.payload;
    },
    setOpponentTab: (state, action) => {
      state.oppTab = action.payload;
    },
    setEmployeeId: (state, action) => {
      state.empId = action.payload;
    },
    setClientInfo: (state, action) => {
      state.clientInfo = action.payload;
    },
    setColumnsList: (state, action) => {
      state.columnsList = action.payload;
    },
  },
  extraReducers: {
    // ==> GET Countries
    [getAllCountries.fulfilled]: (state, action) => {
      state.isLoadingCountries = false;
      state.countries = action.payload.data;
    },
    [getAllCountries.rejected]: (state, action) => {
      state.isLoadingCountries = false;
    },
    [getAllCountries.pending]: (state) => {
      state.isLoadingCountries = true;
    },

    // ==> GET jobs
    [getAllJobs.fulfilled]: (state, action) => {
      state.isLoadingJobs = false;
      state.jobs = action.payload.data;
    },
    [getAllJobs.rejected]: (state, action) => {
      state.isLoadingJobs = false;
    },
    [getAllJobs.pending]: (state) => {
      state.isLoadingJobs = true;
    },

    // ==> GET Currencies
    [getAllCurrencies.fulfilled]: (state, action) => {
      state.isLoadingCurrencies = false;
      state.currencies = action.payload.data;
    },
    [getAllCurrencies.rejected]: (state, action) => {
      state.isLoadingCurrencies = false;
    },
    [getAllCurrencies.pending]: (state) => {
      state.isLoadingCurrencies = true;
    },

    // ==> CREATE JOB
    [createJobServices.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createJobServices.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createJobServices.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //GET JOBs

    [getAllClientsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.clients = action.payload?.data?.data;
      state.clientsNext = action.payload?.data?.next;
      state.clientsLastPage = action.payload?.data?.last_page;
    },
    [getAllClientsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllClientsService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET CLIENT BY ID

    [getClientByIdService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.clientInfo = action.payload.data;
    },
    [getClientByIdService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getClientByIdService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET Opponents

    [getOpponentsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.opponents = action.payload?.data?.data;
    },
    [getOpponentsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getOpponentsService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET ADDMINISTRATIVE

    [getAdministrativeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.administrative = action.payload.data.results;
    },
    [getAdministrativeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAdministrativeService.pending]: (state) => {
      state.isLoading = true;
    },
    //getConsultantsService

    [getConsultantsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.consultants = action.payload.data.results;
    },
    [getConsultantsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getConsultantsService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET LAWERS

    [getLawyersService.fulfilled]: (state, action) => {
      state.isLoadingLawyers = false;
      state.lawers = action.payload.data.results;
    },
    [getLawyersService.rejected]: (state, action) => {
      state.isLoadingLawyers = false;
    },
    [getLawyersService.pending]: (state) => {
      state.isLoadingLawyers = true;
    },

    //GET => get Sub Att Intinal Service

    [getSubAttIntinalService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subAttInitial = action.payload.data.results;
    },
    [getSubAttIntinalService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSubAttIntinalService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET => get Sub Att Appeal Service

    [getSubAttAppealService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subAttAppeal = action.payload.data.results;
    },
    [getSubAttAppealService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSubAttAppealService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET => get Sub Att Discrimination Service

    [getSubAttDiscriminationService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subAttDim = action.payload.data.results;
    },
    [getSubAttDiscriminationService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSubAttDiscriminationService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET => GET CLIENT BY ID

    [createClientService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createClientService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createClientService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //POST => CREATE CLIENT SERVICE

    [createClientService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createClientService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createClientService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //DELETE => DELETE CLIENT SERVICE

    [deleteClientsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [deleteClientsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [deleteClientsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //PUT => PUT CLIENT SERVICE

    [updateClientsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [updateClientsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [updateClientsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //GET => GET ACCOUNTING BRANCH

    [getAccountingBranchService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.accountingBranch = action.payload.data?.choices;
    },
    [getAccountingBranchService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAccountingBranchService.pending]: (state) => {
      state.isLoading = true;
    },

    //GET => GET CARDS IDS

    [getCardsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cardsId = action.payload.data?.choices;
    },
    [getCardsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getCardsService.pending]: (state) => {
      state.isLoading = true;
    },

    //POST => CREATE ROLE SERVICE

    [createRoleService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createRoleService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createRoleService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //POST => CREATE ROLE SERVICE

    [getGroupsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.groups = action.payload.data?.data;
    },
    [getGroupsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getGroupsService.pending]: (state) => {
      state.isLoading = true;
    },
    //GET => GET Schedules

    [getSchedules.fulfilled]: (state, action) => {
      state.isLoadingSchedules = false;
      state.schedulesData = action.payload.data;
    },
    [getSchedules.rejected]: (state, action) => {
      state.isLoadingSchedules = false;
    },
    [getSchedules.pending]: (state) => {
      state.isLoadingSchedules = true;
    },
    //GET => GET Employee Details

    [getEmployeeDetails.fulfilled]: (state, action) => {
      state.isLoadingEmployeeDetails = false;
      state.employeeDetails = action.payload?.data?.data;
    },
    [getEmployeeDetails.rejected]: (state, action) => {
      state.isLoadingEmployeeDetails = false;
    },
    [getEmployeeDetails.pending]: (state) => {
      state.isLoadingEmployeeDetails = true;
    },

    //POST => FILTER CLIENT

    [filterClientService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.clients = action.payload?.data;
    },
    [filterClientService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterClientService.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },

    [resetPasswordService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [resetPasswordService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [resetPasswordService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    [getAllClientsListService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.clients = action.payload.data.data;
      state.clientsLastPage = action.payload.data.last_page;
      state.clientsNext = action.payload.data.next;
    },
    [getAllClientsListService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllClientsListService.pending]: (state) => {
      state.isLoading = true;
    },

    [getClientByPhoneService.fulfilled]: (state, action) => {
      state.isLoading = false;
      window.location.href = "/dashboard/lawsuit/edit-client";
      state.clientInfo = action.payload?.data?.data;
      state.userId = action.payload?.data?.data?.id;
    },
    [getClientByPhoneService.rejected]: (state, action) => {
      state.isLoading = false;

      if (action?.payload?.data?.detail === "Invalid token.") {
        window.location.href = "/auth/welcome-pag";
      }
      if (action?.payload?.message === null) {
        window.location.href = "/dashboard/clients";
      }
    },
    [getClientByPhoneService.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setIsChecked,
  setStepperNumber,
  setTab,
  setDelegationNoteTab,
  setExpertTab,
  setEditPageTab,
  setContractChecked,
  setRadioGroup,
  setSlides,
  setIsChekedSlideBotton,
  setRolTab,
  setUserId,
  setCollectionTab,
  setOpponentTab,
  setEmployeeId,
  setClientInfo,
  setEmployeeName,
  setColumnsList,
  setSTab,
} = slice.actions;
